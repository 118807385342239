<template>
  <div>
    <web-rtc-player :url="value" :streamName="streamName" :appName="appName" v-if="appName.length>0" />
    <video-player :url="value" :options="videoOptions" v-else />
  </div>



</template>
<script>
import WebRtcPlayer from "@/components/WebRtcPlayer.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";

export default {
  data() {
    return {
      videoOptions: {
        autoplay: true,
        controls: false,
        muted: false,
        loop: true,
        fluid: true,
        aspectRatio: "16:9",
        sources: [],
        liveui: true,
        controlBar: {
          fullscreenToggle: false,
          //liveDisplay :true,
        },
        userActions: {
          doubleClick: false,
          //doubleClick: this.myDoubleClickHandler,
        },
      },
    };
  },
  components: {
    WebRtcPlayer, VideoPlayer
  },
  props: {
    value: String,
    streamName: String,
    appName: String,
    thumbnailUrl: String
  },
  created() { },
  methods: {
  },
};
</script>