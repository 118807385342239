<template>
  <div>
    <video ref="videoPlayer" autoplay playsinline muted></video>
  </div>
</template>

<script>
//import '@/common/webrtc/adapter-latest.js';
//import adapter from 'webrtc-adapter';

import WowzaWebRTCPlay from '@/common/webrtc/WowzaWebRTCPlay.js';

export default {
  name: "WebRtcPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    url: String,
    appName: String,
    streamName: String,
  },
  data() {
    return {
      player: null,
    };
  },
  created() {
  },
  mounted() {
    let self = this;
    const wowzaWebRTCPlay = new WowzaWebRTCPlay();
    wowzaWebRTCPlay.on({
      onError: self.errorHandler,
      onStateChanged: (state) => {
        if (state.connectionState === 'connected') {
          self.connected();
        } else {
          self.stopped();
        }
      }
    });
    wowzaWebRTCPlay.set({
      videoElementPlay: self.$refs["videoPlayer"],
      applicationName: self.appName,
      streamName: self.streamName,
      sdpURL: self.url,

    }).then(() => {
      wowzaWebRTCPlay.play();
    });

  },
  beforeDestroy() {

  },
  methods: {

    errorHandler(error) {
      console.error('error', error);
    },
    connected(e) {
      console.error('connected', e);

    },
    stopped(e) {
      console.log('stopped',e);
    },

  }
};
</script>
<style type="text/css">

</style>