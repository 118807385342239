<template>
  <div>
    <web-rtc-player :url="value" :streamName="streamName" :appName="appName" v-if="appName.length>0"  />
    <video-player :url="value" :options="videoOptions" v-else />
  </div>
  
</template>
<script>
import VideoPlayer from "@/components/VideoPlayer.vue";  
import WebRtcPlayer from "@/components/WebRtcPlayer.vue";
export default {
  data() {
    return {
      videoOptions: {
        autoplay: true,
        controls: false,
        muted: true,
        loop: true,
        fluid: true,
        aspectRatio: "16:9",
        sources: [],
      },
    };
  },
  components: {
    VideoPlayer,
    WebRtcPlayer
  },
  props: {
    value: String,
    streamName: String,
    appName: String,
    thumbnailUrl: String
  },
};
</script>