<template>
  <div>
    <video
      v-if="isIos"
      ref="iosVideoPlayer"
      :src="url"
      autoplay
      muted
      playsinline
    ></video>
    <video v-else ref="videoPlayer" autoplay muted></video>
  </div>
</template>

<script>
import Hls from "hls.js";
export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    url: String,
  },
  data() {
    return {
      player: null,
      hls: null,
      isIos: false,
    };
  },
  created() {
    this.isIos = navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  mounted() {
    let self = this;
    if (!self.isIos) {
      if (Hls.isSupported()) {
        console.log("hello hls.js!");
        var config = {
          //liveSyncDurationCount: 1,
          enableWorker: true,
          maxBufferLength: 1,
          liveBackBufferLength: 0,
          liveSyncDuration: 0,
          liveMaxLatencyDuration: 5,
          liveDurationInfinity: true,
          highBufferWatchdogPeriod: 1,
          //liveDurationInfinity: true,
        };
        var hls = new Hls(config);
        self.hls = hls;
        hls.attachMedia(self.$refs.videoPlayer);
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          console.log("video and hls.js are now bound together !");

          hls.loadSource(self.url);
          hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
            console.log(
              "manifest loaded, found " + data.levels.length + " quality level"
            );

            self.$refs["videoPlayer"].play();
          });
          hls.on(Hls.Events.ERROR, function (event, data) {
            var errorType = data.type;
            var errorDetails = data.details;
            var errorFatal = data.fatal;
            console.log("player error", errorType, errorDetails, errorFatal);

            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  // try to recover network error
                  console.log(
                    "fatal network error encountered, try to recover"
                  );
                  hls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  console.log("fatal media error encountered, try to recover");
                  hls.recoverMediaError();
                  break;
                default:
                  // cannot recover
                  hls.destroy();
                  break;
              }
            }
            /*
          switch (data.details) {
            case Hls.ErrorDetails.FRAG_LOAD_ERROR:
              // ....
              break;
            default:
              break;
          }
          */
          });
        });
      } else {
        self.$refs["videoPlayer"].src = self.url;
        self.$refs["videoPlayer"].addEventListener("canplay", function () {
          self.$refs["videoPlayer"].play();
        });
      }
    } else {
      
      self.$refs["iosVideoPlayer"].addEventListener("error", function (e) {
        self.$bvToast.toast(
          `Không tải được luồng phát. Hệ thống sẽ thử lại trong 10s (Mã lỗi ${e.target.error.code})`,
          {
            title: `Thông báo`,
            variant: "warning",
            toaster: "b-toaster-bottom-center",
          }
        );
        setTimeout(function () {
          // self.$bvToast.toast(`Đang thử lại (${self.url})`, {
          //   title: `Thông báo`,
          //   variant: "warning",
          //   toaster: "b-toaster-bottom-center",
          // });

          self.$refs["iosVideoPlayer"].load();
          self.$refs["iosVideoPlayer"].play();
        }, 6000);
      });
    }
  },
  beforeDestroy() {
    if (!self.isIos && this.hls) this.hls.destroy();
  },
};
</script>
<style type="text/css">
</style>