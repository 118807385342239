<template>
  <div>
    <div v-if="errors.length > 0">
      <b-alert show variant="danger" dismissible v-for="(error, idx) in errors" :key="idx">{{ error }}</b-alert>
    </div>
    <div v-if="event">
      <div class="event-title">
        <router-link :to="{ name: 'Home' }">
          <h1>
            {{ event.name }}
          </h1>
        </router-link>

        <div class="pip-wrap">
          <span> PiP </span>
          <b-form-select size="sm" v-model="pipSelected" :options="pipOptions"></b-form-select>
        </div>
      </div>

      <b-row>
        <b-col :md="subStreams.length > 0 ? 8 : 12">
          <div v-if="mainStream" class="app-stream main-stream">
            <div id="container-player">
              <div id="container-player-wrap" :class="
                isFullScreen
                  ? 'fullscreen pip-' + pipSelected + ' ' + togglePlayerClass
                  : ''
              ">
                <player-live v-model="mainStream.url" :streamName="mainStream.streamName" :appName="mainStream.appName"
                  :thumbnailUrl="mainStream.thumbnailUrl" id=" mainplayer"></player-live>

                <span class="name"> {{ mainStream.name }}</span>

                <b-button @click="openFullScreen('container-player')" class="btn-fullscreen" variant="outline-primary">
                  <b-icon icon="fullscreen-exit" v-if="isFullScreen"></b-icon>
                  <b-icon icon="fullscreen" v-else></b-icon>
                </b-button>
                <div v-if="isFullScreen">
                  <div v-for="(preview, idx) in pipStreams" :key="idx" class="player-preview"
                    :id="'previewplayer-' + idx">
                    <player-preview v-model="preview.url" :streamName="preview.streamName" :appName="preview.appName"
                      :thumbnailUrl="preview.thumbnailUrl"></player-preview>
                    <span class="name"> {{ preview.name }}</span>
                  </div>

                  <div class="player-overlay" v-if="pipOptions.length > 1">
                    <b-form-checkbox-group id="pipCheckbox" v-model="pipSelected" :options="pipOptions"
                      name="pipCheckBox"></b-form-checkbox-group>
                  </div>
                </div>
                <div v-else>
                  <span class="viewcount">
                    <b-icon icon="person-fill"></b-icon>
                    {{ viewCount }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="4" v-if="subStreams.length > 0 && !isMainFullScreen">
          <div v-for="(stream, idx) in subStreams" :key="idx" class="sub-stream app-stream">
            <div class="sub-player" :id="'sub-player-' + idx">
              <player-preview v-model="stream.url" :streamName="stream.streamName" :thumbnailUrl="stream.thumbnailUrl"
                :appName="stream.appName"></player-preview>

              <span class="name"> {{ stream.name }}</span>
              <b-button @click="openFullScreen('sub-player-' + idx)" class="btn-fullscreen" variant="outline-primary">
                <b-icon icon="fullscreen-exit" v-if="isFullScreen"></b-icon>
                <b-icon icon="fullscreen" v-else></b-icon>
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import eventApi from "@/api/eventApi";
import PlayerLive from "@/components/PlayerLive.vue";
import PlayerPreview from "@/components/PlayerPreview.vue";

export default {
  data() {
    return {
      errors: [],
      event: undefined,
      events: [],
      loading: false,
      isFullScreen: false,
      isMainFullScreen: false,

      isIos: false,
      viewCount: 0,
      pipSelected: 0,
      togglePlayerClass: "",
    };
  },
  computed: {
    mainStream() {
      return this.event.eventStreams[0];
    },
    subStreams() {
      let subStreams = this.event.eventStreams.filter((e, idx) => {
        return idx > 0;
      });
      return subStreams;
    },
    pipStreams() {
      if (this.event.eventStreams.length > 1) {
        if (this.pipSelected == 1) {
          return this.event.eventStreams.filter((e, idx) => {
            return idx == this.event.eventStreams.length - 1;
          });
        } else if (this.pipSelected == 2) {
          let subStreams = this.event.eventStreams.filter((e, idx) => {
            return idx > 0;
          });
          subStreams.reverse();
          return subStreams;
        }
      }
      return [];
    },
    pipOptions() {
      if (this.event.eventStreams.length > 2) {
        return [
          { value: 0, text: "0 PiP" },
          { value: 1, text: "1 PiP" },
          { value: 2, text: "2 PiP" },
        ];
      }
      if (this.event.eventStreams.length > 1) {
        return [
          { value: 0, text: "0 PiP" },
          { value: 1, text: "1 PiP" },
        ];
      }
      return [{ value: 0, text: "0 PiP" }];
    },
  },
  components: {
    PlayerLive,
    PlayerPreview,
  },
  created() {
    this.isIos = navigator.userAgent.match(/iPhone|iPad|iPod/i);

    this.getEvent();
    this.getEvents();

    this.$userHub.watchStream(this.eventId);

    this.$userHub.$on("GetWatchingStreams", this.GetWatchingStreams);
    this.$userHub.$on("EventDeactivated", this.EventDeactivated);
  },
  beforeDestroy() {
    console.log("beforeDestroy", "live details", this.eventId);
    this.$userHub.unWatchStream(this.eventId);
  },
  mounted() {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
  },
  props: {
    type: Number,
    eventId: Number,
  },
  watch: {
    eventId(val, oldVal) {
      this.getEvent();
      this.$userHub.watchStream(val);
      this.$userHub.unWatchStream(oldVal);
    },
  },
  methods: {
    getEvent() {
      this.loading = true;
      eventApi
        .getEvent(this.eventId)
        .then((response) => {
          this.event = response;
          this.viewCount = response.viewCount;
          this.loading = false;
        })
        .catch((error) => {
          console.log("event error", error);
          this.loading = false;
          this.event = undefined;
          this.errors.push(error.message);
        });
    },
    getEvents() {
      eventApi
        .getEvents({
          type: this.type,
          skip: 0,
          take: 200,
        })
        .then((response) => {
          this.events = response;
        });
    },
    openFullScreen(selectorId) {
      console.log("is full screen", selectorId, this.isFullScreen);
      if (!this.isIos) {
        if (this.isFullScreen) {
          this.isFullScreen = false;
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) {
            /* Firefox */
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
            /* Chrome, Safari and Opera */
            document.webkitExitFullscreen();
          } else if (document.webkitCancelFullScreen) {
            /*  Safari  */
            document.webkitCancelFullScreen();
          } else if (document.msExitFullscreen) {
            /* IE/Edge */
            document.msExitFullscreen();
          } else {
            console.log("not support exit fullscreen");
          }
        } else {
          var elem = document.getElementById(selectorId);

          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.mozRequestFullScreen) {
            /* Firefox */
            elem.mozRequestFullScreen();
          } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            /* Chrome, Safari and Opera */
            if (!document.webkitCurrentFullScreenElement) {
              elem.webkitRequestFullscreen();
            }
          } else if (elem.msRequestFullscreen) {
            /* IE/Edge */
            elem.msRequestFullscreen();
          } else {
            console.log("not support fullscreen");
          }
          this.isFullScreen = true;
        }
      } else {
        if (this.isFullScreen) {
          this.isFullScreen = false;

          document
            .getElementById(selectorId)
            .classList.remove("ios-fullscreen");
        } else {
          this.isFullScreen = true;

          document.getElementById(selectorId).classList.add("ios-fullscreen");
        }
      }

      this.isMainFullScreen =
        this.isFullScreen && selectorId == "container-player";
    },
    checkFullScreenChange() {
      if (document.fullscreenElement) {
        console.log(
          `Element: ${document.fullscreenElement.id} entered fullscreen mode.`
        );
      } else {
        this.isFullScreen = false;
      }
    },
    updateSubPlay(idx, value) {
      if (value == true) {
        let selectidx = this.selectStreams.indexOf(
          this.event.eventStreams[idx]
        );
        if (selectidx > -1) {
          this.selectStreams.splice(selectidx, 1);
        }
        console.log("idx", idx, value, selectidx);
      }

      this.event.eventStreams[idx].isPlay = value;
    },
    GetWatchingStreams(streamId, connections) {
      console.log(
        "GetWatchingStreams Details",
        this.eventId,
        streamId,
        connections
      );
      if (this.eventId == streamId) {
        this.viewCount = connections.length;
      }
    },
    EventDeactivated(eventId) {
      console.log("EventDeactivated", eventId, this.eventId);
      if (this.eventId == eventId) {
        this.getEvent();
      }
    },
  },
};
</script>